<template>
  <div>
    <itprojdetails-tour v-if="$store.state.tour & !loading" />
    <section
      v-if="loading && project == null"
      class="py-5 bg9 bs-shadow mobile-top"
      style="min-height: 194px"
    >
      <div class="container">
        <h1 class="text-white mb0 lh1">Loading Project....</h1>
        <br />
        <div class="hollow-dots-spinner">
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
        </div>
      </div>
    </section>

    <section v-else class="py-5 bg9 bs-shadow mobile-top">
      <div class="container">
        <h1 v-if="project && project.projName" class="text-white mb0 lh1">
          {{ project.projName }}
        </h1>
        <h2 v-if="project && project.id" class="text-white lh1">
          {{ "Project #" + project.id }}
        </h2>
        <p v-if="project && project.projName" class="text-white lh1 mb0">
          {{ project.projDescription }}
        </p>
      </div>
    </section>

    <div v-if="loading && project == null" class="row">
      <div class="col-xs-12 col-sm-12 col-12 col-lg-12">
        <div class="flex-box-wrap">
          <div class="flex-internal">
            <atom-spinner
              :animation-duration="1000"
              :size="40"
              :color="'#575757'"
            />
            <p>Loading......</p>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="container mt30 mb20">
      <!-- col -->
      <div class="row">
        <div class="col-lg-8">
          <div
            id="itprojdetails-step-1"
            class="card-back mb10 rounded bs-shadow p-3"
          >
            <div class="question-header d-flex align-items-center mb5 rounded">
              <div class="d-flex number-showing">
                <router-link
                  :to="{ path: $store.state.route.from.fullPath }"
                  class="mb0 lh1 mlauto list-btn dib d-flex center"
                >
                  <i class="material-icons">keyboard_arrow_left</i>
                  Back
                </router-link>
              </div>

              <div class="pag-wrapper mlauto">
                <h2>{{ project.requestReason }}</h2>
              </div>
            </div>
            <hr />
            <div class="info-top">
              <div class="top-row d-flex">
                <div v-if="project">
                  <h4>Job Information</h4>
                  <h6 class="mt20">
                    <i class="fas fa-user"></i> Lead Sales Person
                  </h6>
                  <p class="text-muted mb5 lh1">
                    <small v-if="project.leadSalesPersonFullName">
                      {{ project.leadSalesPersonFullName }}
                    </small>
                    <small v-else>n/a</small>
                  </p>

                  <h6 class="mt20"><i class="fas fa-user"></i> Submitted By</h6>
                  <p class="text-muted mb5 lh1">
                    <small v-if="project.submitterFullName">
                      {{ project.submitterFullName }}
                    </small>
                    <small v-else>n/a</small>
                  </p>
                </div>
                <div class="status ml">
                  <p v-if="project.sscNetValue" class="badge badge-success">
                    Net Value: {{ project.sscNetValue | currency }}
                  </p>
                  <p v-else class="badge badge-success">Net Value: n/a</p>
                </div>
              </div>

              <h6 class="mt10">
                <i class="fas fa-building"></i> Area Contractor Submitted
              </h6>
              <p class="text-muted mb5 lh1">
                <small v-if="project.submitterAC" class="text-muted">
                  {{ project.submitterAC }}
                </small>
                <small v-else class="text-muted">n/a</small>
              </p>

              <h6 class="mt20"><i class="fas fa-file-alt"></i> Market</h6>
              <p class="text-muted mb5 lh1">
                <small v-if="project.marketID" class="text-muted">
                  {{ project.marketID }}
                </small>
                <small v-else class="text-muted">n/a</small>
              </p>
              <h6 class="mt20">
                <i class="fas fa-file-signature"></i> Products
              </h6>
              <p
                class="text-muted mb10"
                v-for="(part, index) in project.itjobProducts"
                :key="index"
              >
                <small v-if="project.projDescription" class="lh1">
                  {{ part.productId }}
                </small>
                <small v-else class="lh1">n/a</small>
              </p>
              <h6 class="mt20">
                <i class="fas fa-file-signature"></i> Project Explanation
              </h6>
              <p class="text-muted mb20">
                <small v-if="project.projDescription" class="lh1">
                  {{ project.projDescription }}
                </small>
                <small v-else class="lh1">n/a</small>
              </p>
            </div>
          </div>

          <!-- new card -->
          <div
            id="itprojdetails-step-3"
            class="card-back mb10 rounded bs-shadow p-3"
          >
            <h4>Installation Information</h4>
            <hr />
            <h6 class><i class="fas fa-building"></i> Installation AC</h6>
            <p class="text-muted mb5 lh1">
              <small v-if="project.installerAC">
                {{ project.installerAC }}
              </small>
              <small v-else>n/a</small>
            </p>
            <h6 class="mt20">
              <i class="fas fa-file-signature"></i>
              Note to AC
            </h6>
            <p class="text-muted mb5 lh1">
              <small v-if="project.installNotes" class="text-muted">
                {{ project.installNotes }}
              </small>
              <small v-else class="text-muted">n/a</small>
            </p>
          </div>

          <!-- new card -->
          <div
            id="itprojdetails-step-4"
            class="card-back mb10 rounded bs-shadow p-3"
          >
            <h4>End User/Customer Information</h4>
            <hr />
            <h6 class><i class="fas fa-user"></i> Customer Contact Name</h6>
            <p class="text-muted mb5 lh1">
              <small v-if="project.endUserName">
                {{ project.endUserName }}
              </small>
              <small v-else>n/a</small>
            </p>
            <h6 class="mt20"><i class="fas fa-phone"></i> Customer Phone</h6>
            <p class="text-muted mb5 lh1">
              <small v-if="project.endUserPhone">
                {{ project.endUserPhone }}
              </small>
              <small v-else>n/a</small>
            </p>
            <h6 class="mt20"><i class="fas fa-envelope"></i> Customer Email</h6>
            <p class="text-muted mb5 lh1">
              <small v-if="project.endUserEmail">
                {{ project.endUserEmail }}
              </small>
              <small v-else>n/a</small>
            </p>
            <h6 class="mt20">
              <i class="fas fa-building"></i>
              Contact Company
            </h6>
            <p class="text-muted mb5 lh1">
              <small v-if="project.endUserCompany" class="text-muted">
                {{ project.endUserCompany }}
              </small>
              <small v-else class="text-muted">n/a</small>
            </p>
            <p class="text-muted mb5 lh1">
              <small v-if="project.endUserStreet" class="text-muted">
                {{ project.endUserStreet + " " }}
              </small>
              <small v-if="project.endUserCity" class="text-muted">
                {{ project.endUserCity + ", " }}
              </small>
              <small v-if="project.endUserState" class="text-muted">
                {{ project.endUserState + " " }}
              </small>
              <small v-if="project.endUserZip" class="text-muted">
                {{ project.endUserZip }}
              </small>
            </p>
          </div>

          <!-- new card -->
          <div
            id="itprojdetails-step-5"
            class="card-back mb10 rounded bs-shadow p-3"
          >
            <h4>General Contractor Information</h4>
            <hr />

            <h6 class><i class="fas fa-user"></i> Contractor Name</h6>
            <p class="text-muted mb5 lh1">
              <small v-if="project.contractorName">
                {{ project.contractorName }}
              </small>
              <small v-else>n/a</small>
            </p>
            <h6 class="mt20"><i class="fas fa-phone"></i> Contractor Phone</h6>
            <p class="text-muted mb5 lh1">
              <small v-if="project.contractorPhone">
                {{ project.contractorPhone }}
              </small>
              <small v-else>n/a</small>
            </p>
            <h6 class="mt20">
              <i class="fas fa-envelope"></i> Contractor Email
            </h6>
            <p class="text-muted mb5 lh1">
              <small v-if="project.contractorEmail">
                {{ project.contractorEmail }}
              </small>
              <small v-else>n/a</small>
            </p>
            <h6 class="mt20">
              <i class="fas fa-building"></i>
              Contractor Company
            </h6>
            <p class="text-muted mb5 lh1">
              <small v-if="project.contractorCompany">
                {{ project.contractorCompany }}
              </small>
              <small v-else>n/a</small>
            </p>
            <p class="text-muted mb5 lh1">
              <small v-if="project.contractorStreet" class="text-muted">
                {{ project.contractorStreet + " " }}
              </small>

              <small v-if="project.contractorCity" class="text-muted">
                <br />
                {{ project.contractorCity + ", " }}
              </small>

              <small v-if="project.contractorState" class="text-muted">
                {{ project.contractorState + " " }}
              </small>
              <small v-if="project.contractorZip" class="text-muted">
                {{ project.contractorZip + " " }}
              </small>
              <small v-if="project.contractorCounty" class="text-muted">
                {{ project.contractorCounty + " " }}
              </small>
              <small v-else class="text-muted">n/a</small>
            </p>
          </div>

          <!-- new card -->
          <div
            id="itprojdetails-step-6"
            class="card-back mb10 rounded bs-shadow p-3"
          >
            <h4>Architect Information</h4>
            <hr />
            <h6><i class="fas fa-user"></i> Architect Name</h6>
            <p class="text-muted mb5 lh1">
              <small v-if="project.architectName">
                {{ project.architectName }}
              </small>
              <small v-else>n/a</small>
            </p>
            <h6 class="mt20"><i class="fas fa-phone"></i> Architect Phone</h6>
            <p class="text-muted mb5 lh1">
              <small v-if="project.architectPhone">
                {{ project.architectPhone }}
              </small>
              <small v-else>n/a</small>
            </p>
            <h6 class="mt20">
              <i class="fas fa-envelope"></i> Architect Email
            </h6>
            <p class="text-muted mb5 lh1">
              <small v-if="project.architectEmail">
                {{ project.architectEmail }}
              </small>
              <small v-else>n/a</small>
            </p>
            <h6 class="mt20">
              <i class="fas fa-building"></i>
              Architect Company
            </h6>
            <p class="text-muted mb5 lh1">
              <small v-if="project.architectCompany">
                {{ project.architectCompany }}
              </small>
              <small v-else>n/a</small>
            </p>
            <p class="text-muted mb5 lh1">
              <small v-if="project.architectStreet" class="text-muted">
                {{ project.architectStreet + " " }}
              </small>
              <br />
              <small v-if="project.architectCity" class="text-muted">
                {{ project.architectCity + ", " }}
              </small>

              <small v-if="project.architectState" class="text-muted">
                {{ project.architectState + " " }}
              </small>
              <small v-if="project.architectZip" class="text-muted">
                {{ project.architectZip }}
              </small>
              <small v-else class="text-muted">n/a</small>
            </p>
          </div>
        </div>
        <div class="col-lg-4">
          <div
            id="itprojdetails-step-2"
            class="card-back mb10 rounded bs-shadow p-3"
          >
            <ul class="list-group mb-3">
              <li
                class="
                  list-group-item
                  d-flex
                  justify-content-between
                  lh-condensed
                "
              >
                <div class>
                  <h6 class="my-0">
                    <i class="fas fa-file-alt"></i> PROJECT ID
                  </h6>

                  <div>
                    <small class="text-muted">{{ project.id }}</small>
                  </div>
                </div>
                <!--
                <span class="text-success">-$5</span>
                -->
              </li>
              <li
                class="
                  list-group-item
                  d-flex
                  justify-content-between
                  lh-condensed
                "
              >
                <div class>
                  <h6 class="my-0">
                    <i class="fas fa-table"></i> ORDER DATE (est)
                  </h6>

                  <div>
                    <small class="text-muted">{{
                      moment(project.orderDate).format("MM-DD-YYYY")
                    }}</small>
                  </div>
                </div>
                <!--
                <span class="text-success">-$5</span>
                -->
              </li>
              <li
                class="
                  list-group-item
                  d-flex
                  justify-content-between
                  lh-condensed
                "
              >
                <div>
                  <h6 class="my-0">
                    <i class="fas fa-table"></i> SHIP DATE (est)
                  </h6>
                  <small class="text-muted">{{
                    moment(project.shipDate).format("MM-DD-YYYY")
                  }}</small>
                </div>
                <!--
                <span class="text-muted">$12</span>
                -->
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { AtomSpinner } from "epic-spinners";
import Avatar from "vue-avatar";
import itprojdetailsTour from "../components/Tours/itprojdetailsTour";

const auth = {
  headers: {
    Authorization: "Bearer " + localStorage.getItem("SSCUSER"),
    //Authorization: "Bearer " + wontworkqwd2iwedjqoewiudwo
  },
};

export default {
  name: "InterterritorialDetails",
  components: {
    AtomSpinner,
    Avatar,
    itprojdetailsTour,
  },
  data() {
    return {
      ordermanageapi: process.env.VUE_APP_ORDER_MANAGE_API,
      projectId: this.$route.params.id,
      project: null,
      loading: false,
      processing: false,
      preprod: false,
      inprod: false,
      shipped: false,
      open: "",
      pdfPreview: "",
    };
  },
  methods: {
    firstLoad: function () {
      this.loading = true;
      this.$store.commit("setActivePage", "order-manage");
      //return axios.get(this.endPointCompute, auth).then((response) => {
      return axios
        .get(`${this.ordermanageapi}/itproj/${this.projectId}`, auth)
        .then((response) => {
          //console.log(response);
          this.project = response.data.projDetails[0];
          this.loading = false;
          document
            .getElementById("mobileScrollTarget")
            .scrollIntoView({ behavior: "smooth" });
        });
    },
    makeActive(item) {
      //console.log(item);
      if (this.open === item) {
        this.open = "";
      } else {
        this.open = item;
      }
    },
  },
  computed: {
    activeNav() {
      return this.$store.state.activePage;
    },

    colorGetter() {
      return this.$store.state.colors;
    },

    getUsername() {
      return this.$store.state.user;
    },
  },
  created() {
    this.$store.commit("showNavigation");

    // console.log(this.$route.path);
  },
  mounted() {
    document
      .getElementById("mobileScrollTarget")
      .scrollIntoView({ behavior: "smooth" });
    this.firstLoad().then(function () {});
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media all and (min-width: 992px) {
  .flex-box-wrap {
    height: 200px;
    padding: 150px 0 125px 0;
    margin: 0;
  }
}

@media all and (max-width: 991px) {
  .flex-box-wrap {
    height: 100px;
    padding: 75px 0 50px 0;
    margin: 0;
    justify-content: center;
    align-items: center;
  }
}

.card-back {
  background: #fff;
}
.article {
  background-color: #fff;
  line-height: 44px;
  padding: 20px;
  margin-bottom: 5px;
  border-top: 0;
}
.top-wrap {
  display: flex;
  align-items: flex-start;
}
.question-bottom {
  align-items: flex-end;
}
.question-header {
  background: #fff;
  color: #575757;
  display: flex !important;
}
.center {
  align-items: center;
  justify-content: center;
}
.tab-container {
  background: #f2f2f3;
  padding: 10px;
  border-radius: 4px;
}
.nav-tabs {
  border-bottom: none;
}
.nav-tabs .nav-link {
  border: none;
}
#nav-tabContent {
  background: #fff;
  padding: 20px;
  border-radius: 4px;
}
.d-flex {
  display: flex;
}
.ml {
  margin-left: auto;
}
/*
.comments:nth-child(even) {
  background: #efefef;
  padding: 10px;
  margin-bottom: 1rem;
  border-radius: 4px;
}
*/
.comments {
  border-bottom: 1px solid #efefef;
  margin-bottom: 10px;
}
.comments p {
  margin-bottom: 10px;
}
.comments:last-child {
  border-bottom: none;
  margin-bottom: 0;
}
.comments:last-child p {
  margin-bottom: 0;
}
.tab-container a {
  color: #429da8;
}
.mt30 {
  margin-top: 30px;
}
.badge {
  display: inline-block;
  padding: 0.5em 0.8em;
}
.progtrckr {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.progtrckr li {
  display: inline-block;
  text-align: center;
  line-height: 3.5em;
}

.progtrckr[data-progtrckr-steps="2"] li {
  width: 25%;
}
.progtrckr[data-progtrckr-steps="3"] li {
  width: 25%;
}
.progtrckr[data-progtrckr-steps="4"] li {
  width: 25%;
}
.progtrckr[data-progtrckr-steps="5"] li {
  width: 25%;
}

.progtrckr li.progtrckr-done {
  color: #fff;
  border-bottom: 4px solid #fff;
}
.progtrckr li.progtrckr-todo {
  color: #3e4651;
  border-bottom: 4px solid #3e4651;
}

.progtrckr li:after {
  content: "\00a0\00a0";
  margin-left: 28px;
  /* this is the text*/
}
.progtrckr li:before {
  position: relative;
  bottom: -2.5em;
  float: left;
  left: calc(50% - 20px);
  line-height: 1em;
}
.progtrckr li.progtrckr-done:before {
  content: "\2713";
  color: #3e4651;
  background-color: #fff;
  height: 40px;
  width: 40px;
  line-height: 40px;
  border: none;
  border-radius: 40px;
}
.progtrckr li.progtrckr-todo:before {
  /*
  content: "\039F";
  color: #fff;
  background-color: #fff;
  font-size: 2.2em;
  bottom: -1.2em;
  */
  content: "\2713";
  color: #3e4651;
  background-color: #3e4651;
  height: 2.2em;
  width: 2.2em;
  line-height: 2.2em;
  border: none;
  border-radius: 2.2em;
}
/* trackker titles */
.tracker-title-wrap {
  margin-top: 15px;
}
.trckr-title {
  padding-left: 0;
}
.trckr-title li {
  display: inline-block;
  text-align: center;
  line-height: 3.5em;
  width: 25%;
  color: #fff;
}
.trckr-title-done {
  color: #fff !important;
}
.trckr-title-todo {
  color: #3e4651 !important;
}
.badge-success {
  color: #fff;
  background-color: #3e4651;
}
@media all and (max-width: 700px) {
  .trckr-title li {
    font-size: 12px;
  }
}
.up {
  text-transform: uppercase;
}
.lines-table td,
.lines-table th {
  padding: 5px;
}
.small,
small {
  font-size: 90% !important;
}

.table td .small,
small {
  font-size: 90% !important;
}
.bol {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  background: #fff;
  padding: 10px;
  list-style-type: none;
  /*border: 1px solid #dee2e6;*/
  background: #efefef;
  margin-bottom: 5px;
  transition: background 0.8s linear;
  border-radius: 4px;
  cursor: pointer;
}
.bol:hover {
  background: #e6e5e5;
}
.parent {
  display: flex;
  align-items: center;
}
.parent i {
  transition: color 0.8s linear;
}
.parent:hover i {
  color: #000;
}
.parent:hover {
  color: #000;
}
.parent:active i {
  color: #00b4b4;
}
.parent i {
  /*margin-left: auto;*/
}
.child-list {
  background: #fff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  /*transition: height 0.8s linear;*/
  height: 100%;
  max-height: 100%;
  padding: 5px;
  margin-top: 10px;
  border-radius: 4px;
}
.child-list li {
  position: relative;
  list-style-type: none;
  margin-left: 0;
  border-bottom: 1px solid #dedede;
  padding: 5px;
}
.child-list li:last-of-type {
  border-bottom: none;
}
.is-closed {
  max-height: 0;
}
.show {
  height: 100% !important;
  max-height: 100% !important;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}

.fade-enter,
.fade-leave-to
/* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

.smooth-enter-active,
.smooth-leave-active {
  transition: opacity 0.5s;
  height: auto;
}
.smooth-enter,
.smooth-leave-to {
  opacity: 0;
}

.height-enter-active,
.height-leave-active {
  transition: height 0.5s;
  height: auto;
}
.height-enter,
.height-leave-to {
  height: 0;
}
.ship-head {
  display: flex;
  width: 100%;
}
.pallet {
  width: 50%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ship-number {
  width: calc(50% + 3px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media all and (max-width: 700px) {
  .pallet {
    width: 60%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .ship-number {
    width: calc(60% + 3px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.fa-arrow-circle-down {
  margin-left: auto;
}

.w100 {
  width: 100%;
}
.dflex {
  display: flex;
}
.info {
  border-top: 1px solid #dedede;
  padding-top: 5px;
  margin-top: 5px;
}
.truck-no {
  margin-left: -5px;
}
.closeX {
  top: 0;
}
.pdfLink {
  color: #429da8;
  cursor: pointer;
  margin-bottom: 0;
}
.pdfLink:hover {
  color: #00b4b4;
}
.pdfLink:active {
  color: #00b4b4;
}
.lightbox {
  z-index: 10003 !important;
}
</style>
